<template>
    <div class="section">
        <div class="sec-treeprof">
            <v-row class="mt-3">
                <v-col cols="12" md="3">
                    <v-img
                        alt="La Finca Tree"
                        class="shrink tree-img"
                        contain
                        src="../assets/trees/balimbing.jpg"
                        transition="scale-transition"
                        width="320"
                        height="320"
                    />
                </v-col>
                <v-col cols="12" md="9">
                    <div class="row">
                        <div class="col-12-md mt-5">
                            <h2>Lorem Ipsum</h2>
                            <p>Care of: <span class="grower-name">Juan Dela Cruz</span></p>
                            <v-btn
                                id="live-view"
                                class="mt-0 pt-0"
                                color="success"
                                
                                >
                                <!-- :to="{name: 'MyTrees'}" -->
                                VIEW LIVE
                            </v-btn>
                            <h3 class="mt-5">Product Description</h3>
                        </div>
                        
                        <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat id quia tempore rerum reiciendis eligendi, ab quisquam officia iure voluptas optio vel at illum omnis! Quos vitae quaerat aperiam sequi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Error debitis odit nesciunt rem. Magnam, deleniti, laborum natus illo, consequuntur in inventore ea amet ipsam officia recusandae sunt? Culpa, non deserunt!<br><br>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quas quasi quod tempore totam suscipit distinctio blanditiis consequatur recusandae nihil laboriosam amet culpa optio exercitationem sit cupiditate repellat, ipsam aliquid.</div>
                        
                    </div>
                    
                </v-col>
            </v-row>

            <div class="variety mt-8">Memories</div>
            <div class="trees-variety d-flex d-inline-flex">
                <v-img
                alt="La Finca Tree"
                class="shrink mr-5"
                contain
                src="../assets/trees/balimbing.jpg"
                transition="scale-transition"
                width="50"
                height="50"
                />
                <v-img
                alt="La Finca Tree"
                class="shrink mr-5"
                contain
                src="../assets/trees/balimbing.jpg"
                transition="scale-transition"
                width="50"
                height="50"
                />
                <v-img
                alt="La Finca Tree"
                class="shrink mr-5"
                contain
                src="../assets/trees/balimbing.jpg"
                transition="scale-transition"
                width="50"
                height="50"
                />
            </div>

            <div class="variety mt-8"><h3>Information Details</h3></div>
            <div>Variety: Sweet and tart</div>
            <div>Tree Type: Fruit bearing tree</div>
            <div>Mature Size: TREE (10-20' Tall)</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
 .section{padding: 5px 65px 5px 65px;}
    .sec-treeprof{
        margin-top: 20px;
        
        text-align: justify;
    }
    .treeprof-title{
        font-size: 25px;
        font-weight: bold;
    }
    .variety{
        font-size: 20px;
        font-weight: bold;
    }
</style>